<template>
  <div>
    <b-tabs v-model="scanTabIndex">
      <b-tab title="Asset Scans" active>
        <!-- <p>{{organization}}organisation</p> -->
        <b-card-code title="">
          <b-card-code class="bg-light-secondary mb-1" title="Search">
            <b-form style="width: 100%">
              <div class="row" style="padding: 5px">
                <div class="col w-100 d-flex mb-1">
                  <v-select
                    id="asset_filter"
                    class="ml-1"
                    style="width: 50%; background-color: white"
                    v-model="asset_filter"
                    label="text"
                    :options="asset_filter_options"
                    @search="fetchOptions"
                    placeholder="Asset Name or Nickname"
                    @input="searchFn"
                  />
                  <!-- <b-form-select
                    class="ml-1"
                    @change="searchFn"
                    v-model="status_filter"
                    :options="status_filter_options"
                    style="width: 50%"
                  >
                  </b-form-select> -->
                  <v-select
                    class="ml-1"
                    style="width: 70%; background-color: white"
                    multiple
                    v-model="status_filter"
                    label="text"
                    :options="status_filter_options"
                    placeholder="Select Status"
                    @input="searchFn"
                    :reduce="(status_filter) => status_filter.value"
                  />
                  <b-form-select
                    style="width: 50%"
                    class="ml-1"
                    @change="searchFn"
                    v-model="agent_type"
                    :options="agent_types"
                  >
                  </b-form-select>
                  <!-- {{ agent_types }} -->
                  <b-button
                    style="width: 15%"
                    class="ml-1"
                    variant="primary"
                    @click="searchFn"
                  >
                    Search
                  </b-button>
                </div>

                <!-- <div class="col-12 d-flex justify-content-end">
                  <b-button
                    style="width: 12%"
                    class="ml-1"
                    variant="primary"
                    @click="searchFn"
                  >
                    Search
                  </b-button>
                </div> -->
              </div>
            </b-form>
          </b-card-code>

          <!-- <button @click="addAgent()" type="button" class="btn ml-auto btn-primary m-1">
      Add New
    </button> -->
          <!-- <div class="app-fixed-search d-flex align-items-center"> -->
          <div class="d-flex justify-content-center mb-1" v-if="loading">
            <b-spinner class="float-right" label="Floated Right" />
          </div>
          <!-- </div> -->
          <div v-if="!loading">
            <b-table
              responsive
              id="allscansTable"
              :fields="fields"
              :items="items"
              :per-page="perPage"
              v-if="items.length"
              hover
              style="min-height: 200px"
            >
              <template #cell(index)="data">
                {{ data.index + 1 + perPage * (currentPage - 1) }}
              </template>
              <template #cell(asset_name)="data">
                <!-- <div class="primary">
                <router-link
                  class="text-truncate"
                  :to="'/assets/scan/' + data.item.asset.asset_id"
                  >{{ data.item.asset.asset_nickname }} ({{
                    data.item.asset.asset_name
                  }})
                </router-link>
              </div> -->
                <a
                  class="text-truncate"
                  @click="redirectToAssetScan(data.item.asset.asset_id)"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  v-b-tooltip.hover.v-dark.right
                  :title="
                    data.item.asset.asset_nickname +
                    '( ' +
                    data.item.asset.asset_name +
                    ')'
                  "
                >
                  {{ data.item.asset.asset_nickname }}
                </a>
              </template>
              <template #cell(is_scan_paused)="data">
                <div v-if="data.item.is_scan_scheduled">
                  <b-badge variant="success" v-if="!data.item.is_scan_paused"
                    >No</b-badge
                  >
                  <b-badge variant="danger" v-else :title="data.item.errors"
                    >Yes</b-badge
                  >
                </div>
              </template>
              <template #cell(scan_status)="data">
                <b-badge variant="primary" v-if="data.item.scan_status == 0"
                  >Scheduled</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="
                    data.item.scan_status == 1 || data.item.scan_status == 6
                  "
                  >In Progress</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_status == 2"
                  >Completed</b-badge
                >
                <b-badge
                  variant="primary"
                  v-else-if="data.item.scan_status == 5"
                  >Pre-scheduled</b-badge
                >
                <b-badge
                  variant="danger"
                  style="cursor: pointer"
                  :title="data.item.errors"
                  v-else
                  >Failed</b-badge
                >
              </template>
              <template #cell(last_run_at)="data">
                {{ data.item.last_run_at | formatdate }}
              </template>
              <!-- <template #cell(created_at)="data">
              {{ data.item.created_at | formatdate }}
            </template> -->
              <template #cell(scan_schedule_type)="data">
                <b-badge
                  variant="primary"
                  v-if="
                    data.item.schedule &&
                    data.item.schedule.scan_schedule_type == 1
                  "
                  >Daily</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="
                    data.item.schedule &&
                    data.item.schedule.scan_schedule_type == 2
                  "
                  >Weekly</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="
                    data.item.schedule &&
                    data.item.schedule.scan_schedule_type == 3
                  "
                  >Weekends</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="
                    data.item.schedule &&
                    data.item.schedule.scan_schedule_type == 4
                  "
                  >Continous</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="
                    data.item.schedule &&
                    data.item.schedule.scan_schedule_type == 5
                  "
                  >Monthly</b-badge
                >
                <b-badge
                  variant="info"
                  v-else-if="
                    data.item.schedule &&
                    data.item.schedule.scan_schedule_type == 6
                  "
                  >Quarterly</b-badge
                >
                <b-badge variant="danger" v-else>Instant</b-badge>
              </template>
              <template #cell(actions)="data">
                <!-- <button @click="editAgent(data.item.agent_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteAgent(data.item.agent_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
                <b-dropdown
                  size="sm"
                  class=""
                  variant="outline-primary"
                  text="Actions"
                >
                  <template #button-content>
                    <span style="padding-left: 17px; padding-right: 17px"
                      >Actions</span
                    >
                  </template>
                  <b-dropdown-item
                    v-if="
                      data.item.scan_status == 0 || data.item.scan_status == 1
                    "
                    @click="refreshScan(data.item.scan_id)"
                    >Refresh</b-dropdown-item
                  >
                  <!-- <b-dropdown-item @click="getDetails(data.item.scan_id)"
                  >Scan Details</b-dropdown-item
                > -->
                  <!-- <b-dropdown-item
                  v-if="
                    (data.item.agenttype.agent_type_code == 'masscan') |
                      (data.item.agenttype.agent_type_code == 'burpsuit') |
                      (data.item.agenttype.agent_type_code == 'nessus') |
                      (data.item.agenttype.agent_type_code == 'wpscan') |
                      (data.item.agenttype.agent_type_code == 'ipreputation') |
                      (data.item.agenttype.agent_type_code == 'ssllabs') |
                      (data.item.agenttype.agent_type_code == 'zap') |
                      (data.item.agenttype.agent_type_code ==
                        'attack_surface') && data.item.scan_status == 2
                  "
                  @click="getSummary(data.item.scan_id)"
                  >Scan Summary</b-dropdown-item
                > -->
                  <b-dropdown-item @click="gotoAsset(data.item.asset.asset_id)"
                    >Go to Asset</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="
                      (data.item.scan_status == 2) & (data.item.agent_type != 6)
                    "
                    @click="
                      generateScanReport(
                        data.item.scan_id,
                        data.item.asset.org_id
                      )
                    "
                    >Generate Report</b-dropdown-item
                  >
                  <b-dropdown-divider />
                  <b-dropdown-item @click="gotoDelete(data.item.scan_id)"
                    >Delete Scan</b-dropdown-item
                  >
                </b-dropdown>
                <b-button
                  class="ml-1"
                  v-if="
                    (data.item.agenttype.agent_type_code == 'masscan') |
                      (data.item.agenttype.agent_type_code == 'burpsuit') |
                      (data.item.agenttype.agent_type_code == 'nessus') |
                      (data.item.agenttype.agent_type_code == 'wpscan') |
                      (data.item.agenttype.agent_type_code == 'ipreputation') |
                      (data.item.agenttype.agent_type_code == 'ssllabs') |
                      (data.item.agenttype.agent_type_code == 'zap') |
                      (data.item.agenttype.agent_type_code ==
                        'attack_surface') |
                      (data.item.agenttype.agent_type_code == 'dsscan') &&
                    data.item.scan_status == 2
                  "
                  @click="getSummary(data.item.scan_id)"
                  variant="primary"
                  size="sm"
                >
                  Scan Summary
                </b-button>
              </template>
            </b-table>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </div>

          <b-pagination-nav
            v-model="currentPage"
            :numberOfPages="total"
            :total-rows="rows"
            :link-gen="linkGen"
            :per-page="perPage"
            :align="pagination_pos"
            aria-controls="allscansTable"
            use-router
          />
        </b-card-code>
      </b-tab>
      <!-- if it enable go to email harvest section and change tabindex to 4 -->
      <!-- <b-tab title="Asset Group">
        <AssetGroupScan :scanTabIndex="scanTabIndex" />
      </b-tab> -->
      <b-tab title="Asset Discovery" lazy>
        <AssetDiscoveryScan :agent_types="full_agent_types" />
      </b-tab>
      <b-tab title="Domain Discovery" lazy>
        <DomainScan />
      </b-tab>
      <b-tab title="Email Discovery" lazy>
        <EmailHarvest
          :agent_types="full_agent_types"
          :scanTabIndex="scanTabIndex"
        />
      </b-tab>
    </b-tabs>
    <!-- Right Sidebar starts -->
    <b-sidebar
      id="sidebar-task-handler-1"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <!-- Header -->
      <ScanDetail :scan_details="scan_details" :closeSidebar="closeSidebar" />
    </b-sidebar>

    <!-- report generate modal -->
    <b-modal
      ref="report_generator"
      title="Generate Report"
      hide-footer
      size="lg"
    >
      <ReportGenerator
        :report_type="parseInt(3)"
        :scan_id="selected_scan"
        :org_id="org_id"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text> Do you really want to delete ? </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteScan()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import ReportGenerator from "./../common/components/ReportGenerator.vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  VBToggle,
  BSidebar,
  BRow,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BTabs,
  BTab,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import ScanDetail from "./components/details.vue";
import DomainScan from "./domainScan.vue";
import EmailHarvest from "./emailHarvest.vue";
import AssetDiscoveryScan from "./massScan.vue";
import AssetGroupScan from "./assetGroupScan.vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    VBToggle,
    BSidebar,
    ScanDetail,
    BRow,
    BForm,
    BFormInput,
    BFormSelect,
    vSelect,
    BSpinner,
    BButton,
    ReportGenerator,
    BTabs,
    BTab,
    DomainScan,
    EmailHarvest,
    AssetDiscoveryScan,
    AssetGroupScan,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,

      scan_details: [],
      asset_filter: null,
      asset_filter_options: [{ value: null, text: "--Select Asset--" }],
      // status_filter: null,
      status_filter: [],
      status_filter_options: [
        { value: null, text: "--Select Status--" },
        { value: 0, text: "Scheduled" },
        { value: 1, text: "In Progress" },
        { value: 2, text: "Completed" },
        { value: 3, text: "Failed" },
      ],
      fields: [
        { key: "index", label: "#" },
        { key: "asset_name", label: "Asset" },
        { key: "agenttype.agent_type_nicename", label: "Agent" },
        // { key: 'agent_conf.agent.agent_name', label: 'Type' },
        { key: "scan_status", label: "Status" },
        // { key: "is_scan_paused", label: "Scan Status" },
        { key: "scan_schedule_type", label: "Schedule" },
        { key: "last_run_at", label: "Last Run" },
        // { key: "created_at", label: "Created At" },
        // { key: "timezone", label: "Timezone" },
        // { key: 'agent_end_point', label: 'End Point' },
        // { key: 'agent_meta', label: 'Meta' },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // organization: null,
      org_id: null,
      organizations: [],
      loading: false,
      // next_page: null,
      // previous_page: null,
      selected_scan: 0,
      agent_type: null,
      agent_types: [{ value: null, text: "--Select Agent Type--" }],
      openDeleteModal: false,
      scan_id: "",
      currentStatus: this.$route.query.status,
      full_agent_types: [],
      agentTypeFullResult: [],
      scanTabIndex: 0,
    };
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
        // return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
    scanTabIndex: function (newVal, oldVal) {
      this.$router.replace({ query: null });
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    this.loadAgentTypes();
    const queryStatus = this.$route.query.status;
    if (queryStatus) {
      const statusValues = Array.isArray(queryStatus)
        ? queryStatus.map(Number)
        : [Number(queryStatus)];
      this.status_filter = this.status_filter_options
        .filter((option) => statusValues.includes(option.value))
        .map((option) => option.value);
    }
    if (this.organization != null) {
      // this.load();
      // this.loadOrg();
      //this.fetchOptions();
      // this.loadAgentTypes();
      this.searchFn();
    }
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;

      // if (this.is_staff) {
      //   this.loading = true;
      //   const o_options = {
      //     method: "GET",
      //     headers: { "content-type": "application/json" },
      //     url:
      //       process.env.VUE_APP_BASEURL +
      //       "organization/organization/get-all-org",
      //   };
      //   var self = this;
      //   this.$http(o_options).then((res) => {

      //     res.data.map(function (value, key) {
      //       let org = {
      //         value: res.data[key].org_id,
      //         text: res.data[key].org_name,
      //       };

      //       self.organizations.push(org);
      //     });
      //     this.loading = false;
      //   });
      // } else {
      //   this.organization = this.$store.state.app.user.profile.organization;
      //   this.searchFn();
      // }
      // this.searchFn();
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.agentTypeFullResult = res.data;
        this.agentTypeFullResult.map((value) => {
          let atype = {
            agent_type_id: value.agent_type_id,
            agent_type_code: value.agent_type_code,
          };
          this.full_agent_types.push(atype);
        });
        res.data.map(function (value, key) {
          if (
            res.data[key].agent_type_code !== "amass" &&
            res.data[key].agent_type_code !== "emailharvester" &&
            res.data[key].agent_type_code !== "hibp" &&
            res.data[key].agent_type_code !== "cidr" &&
            res.data[key].agent_type_code !== "prowler"
          ) {
            let type = {
              value: res.data[key].agent_type_id,
              text: res.data[key].agent_type_nicename,
            };
            // console.log(res.data[key].profile.organization)
            self.agent_types.push(type);
          }
        });
      });
    },
    load: function () {
      this.onVerticalCollapse();
      // if (this.$route.query.page) this.currentPage = this.$route.query.page;
      // this.loading = true;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/scan/?query=true" +
        "&page=" +
        this.currentPage +
        "&org_id=" +
        this.organization;
      // if (this.$route.query.status) {
      //   url = url + "&status=" + this.currentStatus;
      // }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };

      this.$http(options).then((res) => {
        // console.log(res.data.results,"kkkkkkkkkkkkkkkk");
        // this.loading = true
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    gotoAsset(id) {
      this.$router.push({ name: "Scan Asset", params: { id: id } });
    },
    getDetails(id) {
      // this.$router.push({name: 'Edit Scanner', params: { id: id}});
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-1");
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/details?id=" + id,
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        self.scan_details = res.data;
        //this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
    },
    getSummary(id) {
      this.$router.push({ name: "Scan Summary", params: { id: id } });
    },
    generateScanReport(id, org_id) {
      this.selected_scan = id;
      this.org_id = org_id;

      this.$refs["report_generator"].show();
    },
    closeModal() {
      this.$refs["report_generator"].hide();
    },
    gotoDelete(scan_id) {
      this.openDeleteModal = true;
      this.scan_id = scan_id;
    },
    deleteScan() {
      // if (confirm("Do you really want to delete the scan?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/" + this.scan_id + "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.load();
          this.loadOrg();
          this.searchFn();
        }
      });
      // }
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-1");
    },
    refreshScan: function (id, showtoast) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/" + id + "/refresh",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "success") {
          // if (showtoast) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully refreshed",
              icon: "EditIcon",
              variant: "success",
            },
          });
          // }

          this.load();
        }
      });
    },
    searchFn: function (reset = true) {
      this.onVerticalCollapse();
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/scan?query=true" +
        "&page=" +
        this.currentPage;

      // if (this.$route.query.status && this.status_filter == null) {
      //   url = url + "&status=" + this.currentStatus;
      // }
      if (this.$route.query.status && this.status_filter.length === 0) {
        const queryString = this.$route.query.status
          .map((item) => `status=${item}`)
          .join("&");
        url = url + `&${queryString}`;

        // url = url + "&status=" + this.currentStatus;
        this.status_filter = this.$route.query.status;
        this.filter.push({ status_filter: this.status_filter });
      }

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.agent_type != null) {
        this.filter.push({ agent_type: this.agent_type });
        url = url + "&agent_type=" + this.agent_type;
      }
      if (this.asset_filter != null) {
        url = url + "&asset_id=" + this.asset_filter.value;
      }

      if (this.status_filter.length != 0) {
        const queryString = this.status_filter
          .map((item) => `status=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        // url = url + "&status=" + this.status_filter;
        this.filter.push({ status_filter: this.status_filter });
      }

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };

      this.$http(options).then((res) => {
        // console.log(res.data, "agentdddddd ");
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    //----------- fetch asset list data

    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;

        this.$http(a_options).then((res) => {
          self.asset_filter_options = [];
          // self.asset_filter_options.push({ value: null, text: "--Searching for "+search });
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_filter_options.push(a);
          });
        });
      }
    },

    redirectToAssetScan(asset_id) {
      const assetId = asset_id;
      this.$router.push(`/assets/scan/${assetId}`);
    },

    //  ------------previous asset list

    // fetchOptions: function (search, loading) {
    //   if (search.length > 3) {
    //     this.asset_filter_options = [];
    //     this.loading = true;
    //     // Load assets here
    //     const a_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "asset/org-asset?name=" +
    //         search +
    //         "&org_id=" +
    //         this.organization,
    //     };
    //     var self = this;
    //     this.$http(a_options).then((res) => {
    //       self.asset_options = [];
    //       res.data.map(function (value, key) {
    //         let a = {
    //           value: res.data[key].asset_id,
    //           text:
    //             res.data[key].asset_nickname +
    //             " (" +
    //             res.data[key].assetType.asset_type_name +
    //             ")",
    //         };
    //         // console.log(res.data[key].profile.organization)
    //         self.asset_filter_options.push(a);
    //         self.loading = false;
    //       });
    //     });
    //   }
    // },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.w-60 {
  width: 60% !important;
}
.w-40 {
  width: 40% !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
