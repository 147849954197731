<template >
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <b-card-code title="Scan Overview">
      <div>
        <h5 class="text-primary">Path</h5>
        <p>
          <b-form-textarea
            v-model="scan_details"
            class="border-primary"
            rows="6"
            max-rows="10"
            disabled
          >
          </b-form-textarea>
        </p>
      </div>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  // model: {
  //   prop: 'isTaskHandlerSidebarActive',
  //   event: 'update:is-task-handler-sidebar-active',
  // },
  props: {
    scan_details: {
      type: Array,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
</style>